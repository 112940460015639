<template>
  <div>
    <form-wizard
      :color="is_loading ? '#b0a8ff' : '#38B22D'"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="is_loading ? 'Saving...' : 'Submit'"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- account details tab -->
      <tab-content
        title="New Hire Request Details"
        icon="feather icon-file-text"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-form autocomplete="off" id="candidate-form" class="p-2">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">Enter Hire Request Details.</h5>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="role"
                    rules="required"
                  >
                    <label class="required-l" for="role">Role</label>
                    <b-form-input
                      id="role"
                      v-model="data_local.role"
                      name="role"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Role"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <label class="required-l" for="title">Title</label>
                    <b-form-input
                      id="title"
                      v-model="data_local.title"
                      name="title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="team"
                    rules="required"
                  >
                    <label class="required-l" for="team">Team</label>
                    <b-form-input
                      id="team"
                      v-model="data_local.team"
                      name="team"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Team"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Scope" label-for="scope">
                  <b-form-input
                    id="scope"
                    v-model="data_local.scope"
                    name="Scope"
                    placeholder="Scopes"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Experience" label-for="experience">
                  <b-form-input
                    id="experience"
                    v-model="data_local.experience_required"
                    name="experience_required"
                    placeholder="Experience"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Accountabilities"
                  label-for="accountability"
                >
                  <b-form-textarea
                    id="accountability"
                    v-model="data_local.accountabilities"
                    name="Accountabilities"
                    placeholder="Accountabilities"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Requirements" label-for="requirements">
                  <b-form-textarea
                    id="requirements"
                    v-model="data_local.requirements"
                    name="requirements"
                    placeholder="Requirements"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Shift Type">
                  <v-select
                    v-model="data_local.shift"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="shifts"
                    :clearable="false"
                    placeholder="Select Shift Type.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Skills Type" label-for="skills-type">
                  <v-select
                    v-model="data_local.skill_type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="skills_type"
                    :reduce="(label) => label.code"
                    :clearable="false"
                    placeholder="Select Skills Type.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Skills" label-for="skills">
                  <b-form-textarea
                    id="skills"
                    v-model="data_local.skills_required"
                    name="skills_required"
                    placeholder="Skills"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="New Hire Request Date"
                  label-for="nhr-date"
                >
                  <flat-pickr
                    v-model="data_local.nhr_date"
                    :config="{
                      dateFormat: 'Y-m-d',
                      minDate: 'today',
                      altFormat: 'd F Y',
                      altInput: 'true',
                    }"
                    class="form-control"
                    name="nhr_date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="FullTime / Contract"
                  label-for="period-of-work"
                >
                  <b-form-checkbox
                    v-model="data_local.period_of_work"
                    switch
                    inline
                  >
                    Is Contract Work?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Billable / Non Billable"
                  label-for="billing-type"
                >
                  <b-form-checkbox
                    v-model="data_local.billing_type"
                    switch
                    inline
                  >
                    Is Non-Billable?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Number of Positions"
                  label-for="number-of-positions"
                >
                  <b-form-input
                    id="number-of-positions"
                    v-model="data_local.no_of_positions"
                    type="number"
                    name="no_of_positions"
                    placeholder="No Of Positions"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="false" md="6">
                <b-form-group
                  label="New Hire Request Status"
                  label-for="nhr-status"
                >
                  <v-select
                    v-model="data_local.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="nhrStatusOption"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-status"
                    placeholder="Select Status.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="New Requirement / Replacement"
                  label-for="requirement-type"
                >
                  <b-form-checkbox
                    v-model="data_local.requirement_type"
                    switch
                    inline
                  >
                    Is Replacement Requirement?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Job Description"
                  label-for="job-description"
                >
                  <b-form-textarea
                    id="job-description"
                    v-model="data_local.job_description"
                    name="job_description"
                    placeholder="Job Description"
                  />
                </b-form-group>
              </b-col>
              <b-col v-if="data_local.requirement_type" md="6">
                <b-form-group>
                  <label class="required-l" for="replacing-employee"
                    >Replacing Employee</label
                  >
                  <v-select
                    v-model="data_local.replacing"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="replacementEmployeeOptions"
                    :reduce="(label) => label.code"
                    label="label"
                    :clearable="true"
                    input-id="replacing-employee"
                    placeholder="Select Employee.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="data_local.requirement_type">
                <b-form-group label="Release Date" label-for="release-date">
                  <flat-pickr
                    v-model="data_local.release_date"
                    :config="{
                      dateFormat: 'Y-m-d',
                      minDate: 'today',
                      altFormat: 'd F Y',
                      altInput: 'true',
                    }"
                    class="form-control"
                    name="Release Date"
                    placeholder="Select Date.."
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" class="mt-3">
                <b-form-group
                  v-show="data_local.file === ''"
                  label="NHR Attachments"
                  label-for="files"
                >
                  <file-pond
                    ref="pond"
                    name="files[]"
                    max-file-size="3MB"
                    max-files="1"
                    accepted-file-types="application/pdf"
                    file-validate-type-label-expected-types="Expects a PDF file"
                    allow-multiple="false"
                    required="false"
                  />
                  <small class="text-warning flex items-center">
                    <feather-icon icon="AlertCircleIcon" class="mr-25" />
                    File size should be less than 3MB and allowed formats are
                    <b>.doc, .docx, .pdf</b>
                  </small>
                </b-form-group>
                <div v-if="data_local.file != ''">
                  NHR Attachments
                  <feather-icon
                    v-b-tooltip.hover.top="'View Attachments'"
                    class="btn-link cursor-pointer ml-1"
                    icon="EyeIcon"
                    @click.stop.prevent="openWindow(data_local.file)"
                  />
                  <feather-icon
                    v-b-tooltip.hover.top="'Remove Attachments'"
                    class="btn-link cursor-pointer ml-1"
                    icon="TrashIcon"
                    @click.stop.prevent="removeFile()"
                  />
                </div>
              </b-col>

              <b-col
                md="6"
                class="mt-3"
                v-for="(item, index) in customFields"
                :key="item.id"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    rules=""
                  >
                    <b-form-group
                      v-if="item.type == 'number'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'text'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'rating'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <div class="d-flex align-items-center">
                        <star-rating
                          v-model="
                            data_local.get_customfields_data['field_' + item.id]
                          "
                          :show-rating="false"
                          :star-size="30"
                        ></star-rating>
                        <span
                          v-if="
                            data_local.get_customfields_data['field_' + item.id]
                          "
                          :class="`rating-btn btn ${
                            ratingdescription[
                              data_local.get_customfields_data[
                                'field_' + item.id
                              ]
                            ].class
                          }`"
                        >
                          {{
                            ratingdescription[
                              data_local.get_customfields_data[
                                "field_" + item.id
                              ]
                            ].text
                          }}</span
                        >
                        <input
                          type="hidden"
                          v-model="
                            data_local.get_customfields_data['field_' + item.id]
                          "
                          :name="`custom_fields_data[${item.name}-${item.id}]`"
                        />
                      </div>
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'password'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-input
                        :id="item.name"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        :placeholder="item.label"
                        type="password"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'textarea'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <b-form-textarea
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        trim
                        :placeholder="item.label"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="item.type == 'date'"
                      :label="item.label"
                      label-for="input-1"
                    >
                      <flat-pickr
                        :config="{ dateFormat: 'Y-m-d' }"
                        :name="`custom_fields_data[${item.name}-${item.id}]`"
                        :value="
                          data_local.get_customfields_data['field_' + item.id]
                        "
                        class="form-control"
                        placeholder="Select Date.."
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="item.type == 'textarea_rating' && false"
                      label=""
                      label-for="input-1"
                    >
                      <div class="d-flex align-items-center">
                        <label for="rating-inline">{{ item.label }}</label>
                        <b-form-rating
                          id="rating-inline"
                          icon-clear="slash-circle"
                          :model="`${item.name}_rating`"
                          :name="`custom_fields_data[${item.name}_rating-${item.id}]`"
                          show-clear
                          class="mb-1 ml-auto mr-0"
                          inline
                          show-value
                        ></b-form-rating>
                      </div>
                      <b-form-textarea
                        id="review"
                        :model="`${item.name}_note`"
                        :name="`custom_fields_data[${item.name}_note-${item.id}]`"
                        trim
                        placeholder="Note"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import { required, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import nhrStoreModule from "../../nhrStoreModule";
import bomb from "@/libs/bomb/bomb";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);
export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    vSelect,
    FilePond,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      numeric,
      replacementEmployeeOptions: [],
      is_loading: false,
      nhrStatusOption: [
        { label: "Request Approved", code: 1 },
        { label: "In Progress", code: 2 },
        { label: "Request Completed", code: 3 },
      ],
      skills_type: [
        { label: "Technical", code: 0 },
        { label: "Non Technical", code: 1 },
      ],
      shifts: ["US/UK", "INDIA"],
      customFields: [],
      data_local: {
        role: "",
        title: "",
        team: "",
        experience_required: "",
        skills_required: "",
        period_of_work: false,
        billing_type: false,
        no_of_positions: 1,
        status: 1,
        requirement_type: false,
        job_description: "",
        replacing: null,
        skill_type: 0,
        shift: "",
        release_date: "",
        nhr_date: "",
        get_customfields_data: [],
      },
      myFiles: [],
    };
  },
  setup() {
    const NHR_APP_STORE_MODULE_NAME = "app-nhr";

    // Register module
    if (!store.hasModule(NHR_APP_STORE_MODULE_NAME))
      store.registerModule(NHR_APP_STORE_MODULE_NAME, nhrStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NHR_APP_STORE_MODULE_NAME))
        store.unregisterModule(NHR_APP_STORE_MODULE_NAME);
    });
  },
  created() {
    this.fetch_user_data(this.$route.params.id);
    this.$http.get("/list/employees").then((res) => {
      this.replacementEmployeeOptions = res.data.data;
    });
    this.getCustomFields();
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    removeFile() {
      this.data_local.file = "";
    },
    getCustomFields() {
      this.$store
        .dispatch("app-nhr/getCustomFields")
        .then((res) => {
          this.is_loading = false;
          this.customFields = res.data;
          console.log(this.customFields);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetch_user_data(userId) {
      const self = this;
      this.$store
        .dispatch("app-nhr/fetchNHR", userId)
        .then((res) => {
          self.data_local = res.data.data;
        })
        .catch((err) => {
          if (err.response.status === 404) {
            return;
          }
          console.error(err);
        });
    },
    formSubmitted() {
      this.is_loading = true;
      const self = this;
      const formData = new FormData(document.getElementById("candidate-form"));

      formData.delete("files[]"); // data is the name of the orignal input file; import to delete

      if (self.$refs.pond.getFiles().length) {
        if (
          self.$refs.pond.getFiles()[0].fileType != "application/pdf" &&
          self.$refs.pond.getFiles()[0].fileType != "application/msword" &&
          self.$refs.pond.getFiles()[0].fileType !=
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Only PDF and DOC files are allowed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          self.is_loading = false;
          return;
        }
      }

      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      //check when save and assign interview btn clckd
      if (keys.length != 0) {
        for (let i = 0; i < keys.length; i += 1) {
          formData.append(`files[${i}]`, files[i].file);
        }
      }
      formData.append("nhr_date", self.data_local.nhr_date);
      formData.append("_method", "patch");
      formData.append("skill_type", self.data_local.skill_type);
      formData.append("shift", self.data_local.shift);
      formData.append("replacing", self.data_local.replacing);
      formData.append("release_date", self.data_local.release_date);
      formData.append("no_of_positions", self.data_local.no_of_positions);

      formData.append("period_of_work", this.data_local.period_of_work ? 1 : 0);
      formData.append("billing_type", this.data_local.billing_type ? 1 : 0);
      if (this.data_local.no_of_positions <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Number Of Positions",
            icon: "BellIcon",
            variant: "danger",
            text: "the minimum number of position must be 1.",
          },
        });
        this.is_loading = false;
        return;
      }

      if (this.data_local.requirement_type) {
        if (self.data_local.replacing == null) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Please select replacing employee",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          this.is_loading = false;
          return;
        }
      }
      formData.append(
        "requirement_type",
        this.data_local.requirement_type ? 1 : 0
      );
      this.$store
        .dispatch("app-nhr/editNhr", {
          id: this.$route.params.id,
          data: formData,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Hire Request Updated Successfully",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            this.is_loading = false;
            bomb.realistic();
            setTimeout(() => this.$router.push("/all/nhr-list"), 1000);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Hire Request Update Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! New Hire Request Editing Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            console.log(this.$refs.accountRules.validate());
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
</style>
